import React, { useRef, useState } from "react";
import {
    f7,
    App as F7App,
    View,
    Page,
    Navbar,
    Toolbar,
    Link,
    Block,
    Row,
    Col,
    Button,
    NavRight,
} from "framework7-react";
import { CardItemType, HistoryType } from "./types/entity";
import * as utils from './utils'
import "./App.scss";
import config from "./config";
import { usePwaInstall } from "./hooks";

function App() {
    const [counter, setCounter] = useState(0);
    const history = useRef<HistoryType[]>([]);
    const { supported, isInstalled, pwaInstall } = usePwaInstall()

    const addCounter = ({ count, type, title }: CardItemType) => {
        if (counter + count > 120) return;
        if (history.current.filter((item) => item.type === type).length === 4) {
            f7.toast
                .create({
                    text: `Карта "${utils.capitalize(title)}" уже добавлена 4 раза`,
                    position: "top",
                    closeTimeout: 2000,
                })
                .open();
            return;
        }
        setCounter((prev) => prev + count);
        history.current.push({ count, type });
    };

    const undo = () => {
        if (history.current.length === 0) return;
        const undoValue = history.current.pop() as HistoryType;
        setCounter((prev) => prev - undoValue.count);
    };

    const reset = () => {
        setCounter(0);
        history.current = [];
    };

    return (
        <F7App theme="auto" name="Козел калькулятор" id="com.demoapp.test">
            <View main>
                <Page className="color-theme-black">
                    <Navbar title="Калькулятор козла">
                        <NavRight>
                            {supported() && !isInstalled() && <Link onClick={() => pwaInstall()}>Установить</Link>}
                        </NavRight>
                    </Navbar>
                    <Toolbar bottom>
                        <Link onClick={undo}>Отмена хода</Link>
                        <Link onClick={reset}>Сброс</Link>
                    </Toolbar>
                    <div className="background" />
                    <div className="filter" />
                    <div className="counter">{counter}</div>
                    <Block>
                        <Row style={{ marginBottom: 16 }}>
                            {config.cards.map((item) => (
                                <Col
                                    key={item.type}
                                    width={33}
                                    style={{ marginBottom: 16 }}
                                >
                                    <Button
                                        onClick={() =>
                                            addCounter(item)
                                        }
                                        className="card-button"
                                        raised
                                    >
                                        <div
                                            className="image"
                                            style={{
                                                backgroundImage: `url(${require(`./assets/images/${item.type}.png`)})`,
                                            }}
                                        />
                                        <div className="under-text">
                                            {utils.capitalize(item.title)} (+
                                            {item.count})
                                        </div>
                                    </Button>
                                </Col>
                            ))}
                        </Row>
                    </Block>
                </Page>
            </View>
        </F7App>
    );
}

export default App;
