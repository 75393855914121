// Import React
import React from 'react';

// Import ReactDOM
import ReactDOM from 'react-dom/client';

// Import F7 Bundle
//@ts-ignore
import Framework7 from 'framework7/lite-bundle';
// Import F7-React Plugin
import Framework7React from 'framework7-react';
import 'framework7/css/bundle'

// Import Main App component
import App from './App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import './index.css'

// Init F7-React Plugin
Framework7.use(Framework7React);

// Mount React App
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <App />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();