import { CardItemType } from "../types/entity";

const cards: CardItemType[] = [
    {
        type: 'jack',
        count: 2,
        title: 'валет'
    },
    {
        type: 'queen',
        count: 3,
        title: 'дама'
    },
    {
        type: 'king',
        count: 4,
        title: 'король'
    },
    {
        type: 'ten',
        count: 10,
        title: 'десять'
    },
    {
        type: 'ace',
        count: 11,
        title: 'туз'
    },
]

const config = {
    cards
}

export default config